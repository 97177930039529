import React, { useState } from "react";
import "../App.css";
import { Button } from "./Button";
import "./LocationSchedule.css";
import campus from "./images/locations/UCB.png";
import run from "./images/locations/5kRun.jpg";

function ConfSchedule() {
  const [isEnlarged, setIsEnlarged] = useState(false);

  const enlargeImage = () => {
    setIsEnlarged(!isEnlarged);
  };

  return (
    <div className="container">
      {/* <image src='images/ocean-image.jpg'/> */}
      <div className="us-image">
        <div className="header">
          Locations
          <br />
          {/* <img className='ucbmun-image' src={require('./images/ucbmun.png')} alt='UCBMUN Logo' /> */}
        </div>
        <br />
        <br />
        <br />
      </div>
      <br />
      <br />
      <br />
      <div className="letter">
        {/* <h1>Coming soon!</h1> */}
        <p>
          Please note that Social Sciences and Barrows are the same building.
          <br />
          <br />
        </p>
        <br />
        <div className="building">
          <h1>Ceremonies</h1>
          <br />
          <div className="location-box">
            <div className="room-box-ceremony">
              <p>Dwinelle Hall</p>
              <br />
              <p>Hearst Field Annex 1</p>
              <br />
              <p>Hearst Field Annex 1</p>
              <br />
            </div>
            <div className="room-box-ceremony">
              <p>Registration</p>
              <br />
              <p>Opening Ceremony</p>
              <br />
              <p>Closing Ceremony</p>
              <br />
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="location">
          <div className="building">
            <h1>General</h1>
            <br />
            <div className="location-box">
              <div className="room-box">
                <p>Social Sciences 185</p>
                <br />
                <p>Social Sciences 54</p>
                <br />
                <p>Social Sciences 174</p>
                <br />
                <p>Dwinelle 79</p>
                <br />
                <p>Dwinelle 88</p>
                <br />
              </div>
              <div className="room-box">
                <p>6th Legal</p>
                <br />
                <p>UNOOSA</p>
                <br />
                <p>IAEA</p>
                <br />
                <p>NATO</p>
                <br />
                <p>UNHCR</p>
                <br />
              </div>
            </div>
          </div>
          <br />
          <div className="building">
            <h1>Specialized</h1>
            <br />
            <div className="location-box">
              <div className="room-box" style={{ width: "22.5vh" }}>
                <p>Social Sciences 18</p>
                <br />
                <p>Dwinelle 87</p>
                <br />
                <p>Social Sciences 129</p>
                <br />
                <p>Dwinelle 83</p>
                <br />
                <p>Dwinelle 263</p>
                <br />
              </div>
              <div className="room-box" style={{ width: "22.5vh" }}>
                <p>Cuisines du Mondes</p>
                <br />
                <p>Taylor Swiift</p>
                <br />
                <p>Formula 1</p>
                <br />
                <p>OJ Simpson</p>
                <br />
                <p>Disney</p>
                <br />
              </div>
            </div>
          </div>
          <br />
          <div className="building">
            <h1>Crisis</h1>
            <br />
            <div className="location-box">
              <div className="room-box">
                <p>Dwinelle 250</p>
                <br />
                <p>Dwinelle 283</p>
                <br />
                <p>Dwinelle 262</p>
                <br />
                <p>Dwinelle 255</p>
                <br />
                <p>Dwinelle 254</p>
                <br />
                <p>Dwinelle 251</p>
                <br />
              </div>
              <div className="room-box">
                <p>UNSC</p>
                <br />
                <p>Barbenheimer</p>
                <br />
                <p>Percy Jackson</p>
                <br />
                <p>JCC: Adani</p>
                <br />
                <p>JCC: CBI</p>
                <br />
                <p>ADHOC</p>
                <br />
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <h1>Campus Map</h1>
        <br />
        <p>Note: Click to enlarge.</p>
        <br />
        <img
          src={campus}
          alt="Berkeley Campus Map"
          style={{
            width: isEnlarged ? "100%" : "70%", // Toggle between 50% and 100%
            transition: "width 0.3s ease", // Smooth transition
          }}
          onClick={enlargeImage} // Call enlargeImage when clicked
        />
        <br />
        <h1>Berkeley 5k Run Map</h1>
        <br />
        <p>Note: Click to enlarge.</p>
        <br />
        <img
          src={run}
          alt="Berkeley Campus Map"
          style={{
            width: isEnlarged ? "100%" : "70%", // Toggle between 50% and 100%
            transition: "width 0.3s ease", // Smooth transition
          }}
          onClick={enlargeImage} // Call enlargeImage when clicked
        />
        <br />
      </div>
      <br />
      <br />
    </div>
  );
}

export default ConfSchedule;
